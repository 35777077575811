import dynamic from "next/dynamic";

interface Props {
  centerId: string;
}

const BankLogo = dynamic(() => import("../logos"), {
  loading: () => <div></div>,
});

const BankLogoComponent = ({ centerId }: Props) => <BankLogo centerId={centerId} />;

export default BankLogoComponent;
