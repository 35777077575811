import Header from "./header";
import { PortalFooter } from "../../footer";
import { ILayout } from "./types";
import { NORNE_CENTERID } from "@portal/assets/banks";

const LayoutNotAuthorized = ({ children, centerId }: React.PropsWithChildren<ILayout>) => (
  <div className={`flex flex-col w-full text-defaultText bg-greyBackground min-h-[100vh]`}>
    <div className="print:hidden bg-headerBackground text-headerMenuText">
      <Header centerId={centerId ?? NORNE_CENTERID} />
    </div>
    <div className="container mx-auto md:my-4">{children}</div>
    <PortalFooter centerId="" />
  </div>
);

export default LayoutNotAuthorized;
